.rowTitle{
    font-size: large;
    text-transform: uppercase;
    font-weight: bolder;
}
.keyLabelCard{
    font-size: small;
    font-weight: bold;    
}
.valueLabelCard{
    font-size: small;
    text-transform: uppercase;
}
.emailLabelCard{
    font-size: small;
    text-transform: lowercase; 
}