.gridEditIcon{
    font-size: 24px;
    color: black;
}
.gridRemoveIcon{
    font-size: 24px;
    color: var(--ion-color-danger);
}
.cancelButton{
    color: var(--ion-color-medium)!important;
}
.dangerButton{
    color: var(--ion-color-danger)!important;
}