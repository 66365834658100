ion-card-content{
    background: #EEEEEE;
  }
ion-input{
    background: white !important;
  }

ion-select{
    background: white !important;
}  
    
