/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  /** primary **/
  --ion-color-primary: #32baca;
  --ion-color-primary-rgb: 61, 194, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: rgb(49, 154, 202);
  --ion-color-primary-tint: #50c8ff;

  /** secondary **/
  --ion-color-secondary: #32baca;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: rgb(49, 154, 202);
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #0c6ba3;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #014f7b;
  --ion-color-tertiary-tint: #23709d;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
 
  
  --ion-background-color:whitesmoke;
  --ion-background-color-rgb: 198,224,217;
  
  
}
ion-input{  
  border-radius: 5px !important;
  padding-right: 30px;
  font-size: 0.9em;
  border: 1px solid #e0e0e0 !important;
  
}
ion-card-content{
  background: white;
}
ion-card-title{
  background: white;
}
ion-card-header{
  background: white;
}
ion-select{
  border-radius: 5px !important;
  padding-right: 30px;
  font-size: 0.9em;
  border: 1px solid #e0e0e0 !important;
  
}
ion-header{
  background: white;
}
ion-list{
  background: white;
}
body{
  align-items: center;
}
.itemCardTitle{
  font-weight: bolder;
}
.itemCardKey{
  font-weight: bold;
  font-size: small;
}
.itemCardValue{
  font-size: medium;
}
.formKeyLabel{
  font-weight: bold;
}
.totalLabel{
  font-weight: bold;
  color: var(--ion-color-primary);
}
.totalValue{
  font-weight: bold;
  color: var(--ion-color-primary);
  font-size: large;
}
.gridHeaderCol{
  background-color: #bbbbbb;
  color: var(--ion-color-primary);
  font-size: medium;
  font-weight: bold;
}
.fabButtonGreen{
  border-style: solid;
  border-radius: 50%;
  border-width: 2px;
  border-color: rgb(107, 235, 107);
}
.fabButtonRed{
  border-style: solid;
      border-radius: 50%;
      border-width: 2px;
      border-color: red;
}
.colAlineada{
  display: flex!important;
   align-content: center!important;
   align-items: center!important;
}
.okButton{
  color: crimson !important;
}
.cancelButton{          
  color: magenta !important;
}

input[type="date"]:not(.has-value):before{
  color: lightgray;
  content: attr(value);
}



.my-custom-modal-css .modal-wrapper {
  height: 100%;
  top: 0%;
  width: 90%;
  position: absolute; 
  display: block;  
}

.scrollgrid{
  height: 100px;
  overflow-y: scroll;
}
.faltantes{
  height:115px;
  overflow-y:scroll;
}
.objetivoLabel{
  color: grey;
}
.textarea{
  background: white;
}
.objetivoKeyLabel{
  font-weight: bold;
  width: 125px;
}
.articulosGridItem{
  font-size: x-small;
}
.articulosGridCel{
  height: max-content;
}