div.upper{
    height: 30vh;
    
}
div.middle{
    height: 55vh;    
}
img{
    max-width: 100%;
    max-height: 100%;
    display: block; /* remove extra space below image */    
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}
.item.item-content{
    background-color: cadetblue;
    background: cadetblue;
}
.titlePaciente{
    width: 100%;
    background: blue;
}