.keyLabel{
    font-size: medium;
    height: 35px;
}
.centeredCol{
    vertical-align: middle;
}
.fullHeight{
    height: 100%;
}
.formCard{
    width: 60%
}