div.upper{
    height: 30vh;
}
div.lower{
    height: 57vh;
}
.imageDiv{    
    height: 250px;
    width: 50%; 
}
.profileImage{    
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.keyLabel{
    font-size: medium;
    height: 35px;
}