.welcome-card img {
  max-height: 35vh;
  overflow: hidden;
}
.body{
  height: 95%;
}
.keyLabel{
  font-weight: bold;
}
